import React, {useContext, useState} from 'react';
import { IAppComponentProps } from "../resources/component-interface";
import SearchIcon from "./Icon/SearchIcon";
import {LocaleContext} from "./Locale/LocaleProvider";
import configUrl from "../lib/config-url";
import {AppConfigContext} from "../context/AppConfigProvider";
import Extr4SearchIcon from "./Icon/Extr4SearchIcon";

interface IComponentProps extends IAppComponentProps  {}

const Search: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const cssClass = className + " ImacIcon ImacIcon--bg-transperent imac-my-6 ImacIcon--color-black";
    const  { config } = useContext(AppConfigContext);
    const { t } = useContext(LocaleContext);
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const overlayClass = 'imac-overlay-open-bg ' + (isOpen ? ' visible' : '');
    const searchPopupClass = 'ImacSearchPopup' + (isOpen ? ' open' : '');

    const isExtr4 = config.cssThemeClass === 'theme-class--extr4';

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleChange = (e:any) => {
        setSearchQuery(e.target.value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        const searchStringEncoded = encodeURIComponent(searchQuery);
        window.location.href = config.context === 'wordpress' ?
            config.baseUrl + `${config.storeCode}/` + configUrl.search + searchStringEncoded :
            config.baseUrl + configUrl.search + searchStringEncoded;
    }

    return (
        <>
            <div className={searchPopupClass}>
                <span className="ImacSearchPopup__title" >{t('Start your search')}</span>
                <form className="ImacSearchPopup__form" onSubmit={handleSubmit}>
                    <input className="ImacSearchPopup__input" value={searchQuery} onChange={handleChange} placeholder={t('Search ...')}/>
                </form>
            </div>
            <div className={overlayClass}  onClick={handleClose}></div>
            {isExtr4 ? <div className={className + " ImacIcon ImacIcon--bg-transparent imac-my-6"} onClick={handleOpen}>
                    <Extr4SearchIcon />
                </div> : 
                <div className={cssClass} onClick={handleOpen}>
                    <SearchIcon />
                </div>
            }
            
        </>

    );
};

export default Search;
